import React from "react"
import SinglePost from "../components/SinglePost"
import { graphql } from "gatsby"
import Pager from "../components/Pager"
import PageTitle from "../components/PageTitle"
import PropTypes from "prop-types"
import Seo from "../components/seo"
import siteCardImage from "../../static/card.jpg"

export const allPosts = graphql`
  query ($skip: Int!, $limit: Int!, $name: String!) {
    allStrapiPost(
      sort: { fields: published, order: DESC }
      filter: {
        active: { eq: true }
        categories: { elemMatch: { name: { in: [$name] } } }
      }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        title
        strapiId
        active
        slug
        published(formatString: "MMM D YYYY")
        summary
        content
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 95)
            }
          }
        }
        categories {
          name
        }
      }
    }
  }
`

const TagTemplate = ({ data, pageContext }) => {
  const posts = data.allStrapiPost.nodes
  const shareCardGenerel = {
    src: siteCardImage,
    width: 1200,
    height: 628,
  }

  const url = pageContext.name.replace(" ", "-").toLowerCase()

  return (
    <>
      <Seo
        title={pageContext.name}
        image={shareCardGenerel}
        url={`/tags/${url}/`}
      />

      <PageTitle title={pageContext.name} />
      {posts.map((post, index) => {
        return <SinglePost item={post} key={index} />
      })}

      <Pager pageContext={pageContext} />
    </>
  )
}

TagTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default TagTemplate
